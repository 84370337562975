





























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import {
  Trade,
  PairHistory,
  EMPTY_PLOTCONFIG,
  PlotConfig,
  PairCandlePayload,
  PairHistoryPayload,
} from '@/types';
import CandleChart from '@/components/charts/CandleChart.vue';
import PlotConfigurator from '@/components/charts/PlotConfigurator.vue';
import { getCustomPlotConfig, getPlotConfigName } from '@/shared/storage';
import { BotStoreGetters } from '@/store/modules/ftbot';
import { SettingsGetters } from '@/store/modules/settings';

const ftbot = namespace('ftbot');
const uiSettingsNs = namespace('uiSettings');

@Component({ components: { CandleChart, PlotConfigurator } })
export default class CandleChartContainer extends Vue {
  @Prop({ required: true }) readonly availablePairs!: string[];

  @Prop({ required: true }) readonly timeframe!: string;

  @Prop({ required: false, default: () => [] }) readonly trades!: Array<Trade>;

  @Prop({ required: false, default: false }) historicView!: boolean;

  @Prop({ required: false, default: true }) plotConfigModal!: boolean;

  /** Only required if historicView is true */
  @Prop({ required: false, default: false }) timerange!: string;

  /**
   * Only required if historicView is true
   */
  @Prop({ required: false, default: false }) strategy!: string;

  pair = '';

  plotConfig: PlotConfig = { ...EMPTY_PLOTCONFIG };

  plotConfigName = '';

  showPlotConfig = this.plotConfigModal;

  @Getter getChartTheme!: string;

  @ftbot.State availablePlotConfigNames!: Array<string>;

  @ftbot.Action setPlotConfigName;

  @ftbot.State candleData!: PairHistory;

  @ftbot.State history!: PairHistory;

  @ftbot.Getter [BotStoreGetters.selectedPair]!: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action public getPairCandles!: (payload: PairCandlePayload) => void;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action public getPairHistory!: (payload: PairHistoryPayload) => void;

  @uiSettingsNs.Getter [SettingsGetters.timezone]: string;

  get dataset(): PairHistory {
    if (this.historicView) {
      return this.history[`${this.pair}__${this.timeframe}`];
    }
    return this.candleData[`${this.pair}__${this.timeframe}`];
  }

  get datasetColumns() {
    return this.dataset ? this.dataset.columns : [];
  }

  get hasDataset(): boolean {
    return !!this.dataset;
  }

  mounted() {
    if (this.selectedPair) {
      this.pair = this.selectedPair;
    } else if (this.availablePairs.length > 0) {
      [this.pair] = this.availablePairs;
    }
    this.plotConfigName = getPlotConfigName();
    this.plotConfig = getCustomPlotConfig(this.plotConfigName);
  }

  plotConfigChanged() {
    console.log('plotConfigChanged');
    this.plotConfig = getCustomPlotConfig(this.plotConfigName);
    this.setPlotConfigName(this.plotConfigName);
  }

  showConfigurator() {
    if (this.plotConfigModal) {
      this.$bvModal.show('plotConfiguratorModal');
    } else {
      this.showPlotConfig = !this.showPlotConfig;
    }
  }

  refresh() {
    if (this.pair && this.timeframe) {
      if (this.historicView) {
        this.getPairHistory({
          pair: this.pair,
          timeframe: this.timeframe,
          timerange: this.timerange,
          strategy: this.strategy,
        });
      } else {
        this.getPairCandles({ pair: this.pair, timeframe: this.timeframe, limit: 500 });
      }
    }
  }

  @Watch('availablePairs')
  watchAvailablePairs() {
    if (!this.availablePairs.find((pair) => pair === this.pair)) {
      [this.pair] = this.availablePairs;
      this.refresh();
    }
  }

  @Watch(BotStoreGetters.selectedPair)
  watchSelectedPair() {
    this.pair = this.selectedPair;
    this.refresh();
  }
}
