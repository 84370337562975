
































































import { ChartType, IndicatorConfig } from '@/types';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import randomColor from '@/shared/randomColor';

@Component({})
export default class PlotIndicator extends Vue {
  @Prop({ required: true }) value!: Record<string, IndicatorConfig>;

  @Prop({ required: true }) columns!: string[];

  @Prop({ required: true }) addNew!: boolean;

  @Emit('input')
  emitIndicator() {
    return this.combinedIndicator;
  }

  selColor = randomColor();

  graphType: ChartType = ChartType.line;

  availableGraphTypes = Object.keys(ChartType);

  selAvailableIndicator = '';

  cancelled = false;

  @Watch('value')
  watchValue() {
    [this.selAvailableIndicator] = Object.keys(this.value);
    this.cancelled = false;
    if (this.selAvailableIndicator && this.value) {
      this.selColor = this.value[this.selAvailableIndicator].color || randomColor();
      this.graphType = this.value[this.selAvailableIndicator].type || ChartType.line;
    }
  }

  @Watch('selColor')
  watchColor() {
    this.emitIndicator();
  }

  clickCancel() {
    this.cancelled = true;
    this.emitIndicator();
  }

  get combinedIndicator() {
    if (this.cancelled || !this.selAvailableIndicator) {
      return {};
    }
    return {
      [this.selAvailableIndicator]: {
        color: this.selColor,
        type: this.graphType,
      },
    };
  }

  newColor() {
    this.selColor = randomColor();
  }
}
