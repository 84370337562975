























































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PlotConfig, EMPTY_PLOTCONFIG, IndicatorConfig } from '@/types';
import { getCustomPlotConfig } from '@/shared/storage';
import PlotIndicator from '@/components/charts/PlotIndicator.vue';
import { BotStoreGetters } from '@/store/modules/ftbot';

const ftbot = namespace('ftbot');

@Component({
  components: { PlotIndicator },
})
export default class PlotConfigurator extends Vue {
  @Prop({ required: true }) value!: PlotConfig;

  @Prop({ required: true }) columns!: Array<string>;

  @Prop({ required: false, default: true }) asModal!: boolean;

  @Emit('input')
  emitPlotConfig() {
    return this.plotConfig;
  }

  @ftbot.Action getStrategyPlotConfig;

  @ftbot.Getter [BotStoreGetters.strategyPlotConfig];

  get selIndicator(): Record<string, IndicatorConfig> {
    if (this.addNewIndicator) {
      return {};
    }
    if (this.selIndicatorName) {
      return {
        [this.selIndicatorName]: this.currentPlotConfig[this.selIndicatorName],
      };
    }
    return {};
  }

  set selIndicator(newValue: Record<string, IndicatorConfig>) {
    console.log('newValue', newValue);
    const name = Object.keys(newValue)[0];
    // this.currentPlotConfig[this.selIndicatorName] = { ...newValue[name] };
    // this.emitPlotConfig();
    if (name && newValue) {
      this.addIndicator(newValue);
    } else {
      this.addNewIndicator = false;
    }
  }

  plotConfig: PlotConfig = EMPTY_PLOTCONFIG;

  plotOptions = [
    { text: 'Main Plot', value: 'main_plot' },
    { text: 'Subplots', value: 'subplots' },
  ];

  plotConfigName = 'default';

  newSubplotName = '';

  selAvailableIndicator = '';

  selIndicatorName = '';

  addNewIndicator = false;

  showConfig = false;

  selSubPlot = 'main_plot';

  tempPlotConfig?: PlotConfig = undefined;

  tempPlotConfigValid = true;

  @ftbot.Mutation saveCustomPlotConfig;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Mutation updatePlotConfigName!: (plotConfigName: string) => void;

  @ftbot.State('plotConfigName') usedPlotConfigName!: string;

  get plotConfigJson() {
    return JSON.stringify(this.plotConfig, null, 2);
  }

  set plotConfigJson(newValue: string) {
    try {
      this.tempPlotConfig = JSON.parse(newValue);
      // TODO: Should Validate schema validity (should be PlotConfig type...)
      this.tempPlotConfigValid = true;
    } catch (err) {
      this.tempPlotConfigValid = false;
    }
  }

  get subplots(): string[] {
    // Subplot keys (for selection window)
    return ['main_plot', ...Object.keys(this.plotConfig.subplots)];
  }

  get usedColumns(): string[] {
    if (this.isMainPlot) {
      return Object.keys(this.plotConfig.main_plot);
    }
    if (this.selSubPlot in this.plotConfig.subplots) {
      return Object.keys(this.plotConfig.subplots[this.selSubPlot]);
    }
    return [];
  }

  get isMainPlot() {
    return this.selSubPlot === 'main_plot';
  }

  get currentPlotConfig() {
    if (this.isMainPlot) {
      return this.plotConfig.main_plot;
    }

    return this.plotConfig.subplots[this.selSubPlot];
  }

  mounted() {
    console.log('Config Mounted', this.value);
    this.plotConfig = this.value;
    this.plotConfigName = this.usedPlotConfigName;
  }

  @Watch('value')
  watchValue() {
    this.plotConfig = this.value;
    this.plotConfigName = this.usedPlotConfigName;
  }

  addIndicator(newIndicator: Record<string, IndicatorConfig>) {
    console.log(this.plotConfig);

    const { plotConfig } = this;
    const name = Object.keys(newIndicator)[0];
    const indicator = newIndicator[name];
    if (this.isMainPlot) {
      console.log(`Adding ${name} to MainPlot`);
      plotConfig.main_plot[name] = { ...indicator };
    } else {
      console.log(`Adding ${name} to ${this.selSubPlot}`);
      plotConfig.subplots[this.selSubPlot][name] = { ...indicator };
    }

    this.plotConfig = { ...plotConfig };
    // Reset random color
    this.addNewIndicator = false;
    this.emitPlotConfig();
  }

  removeIndicator() {
    console.log(this.plotConfig);
    const { plotConfig } = this;
    if (this.isMainPlot) {
      console.log(`Removing ${this.selIndicatorName} from MainPlot`);
      delete plotConfig.main_plot[this.selIndicatorName];
    } else {
      console.log(`Removing ${this.selIndicatorName} from ${this.selSubPlot}`);
      delete plotConfig.subplots[this.selSubPlot][this.selIndicatorName];
    }

    this.plotConfig = { ...plotConfig };
    console.log(this.plotConfig);
    this.selIndicatorName = '';
    this.emitPlotConfig();
  }

  addSubplot() {
    this.plotConfig.subplots = {
      ...this.plotConfig.subplots,
      [this.newSubplotName]: {},
    };
    this.selSubPlot = this.newSubplotName;
    this.newSubplotName = '';

    console.log(this.plotConfig);
    this.emitPlotConfig();
  }

  delSubplot() {
    delete this.plotConfig.subplots[this.selSubPlot];
    this.plotConfig.subplots = { ...this.plotConfig.subplots };
    this.selSubPlot = '';
  }

  savePlotConfig() {
    this.saveCustomPlotConfig({ [this.plotConfigName]: this.plotConfig });
  }

  loadPlotConfig() {
    this.plotConfig = getCustomPlotConfig(this.plotConfigName);
    console.log(this.plotConfig);
    console.log('loading config');
    this.emitPlotConfig();
  }

  loadConfigFromString() {
    // this.plotConfig = JSON.parse();
    if (this.tempPlotConfig !== undefined && this.tempPlotConfigValid) {
      this.plotConfig = this.tempPlotConfig;
      this.emitPlotConfig();
    }
  }

  resetConfig() {
    this.plotConfig = { ...EMPTY_PLOTCONFIG };
  }

  async loadPlotConfigFromStrategy() {
    await this.getStrategyPlotConfig();
    this.plotConfig = this.strategyPlotConfig;
    this.emitPlotConfig();
  }
}
